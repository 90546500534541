import React from 'react';

interface Props {
	isActive: boolean;
}

const TripleBar: React.FC<Props> = ({ isActive }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6 7H18" stroke={isActive ? '#7A0021' : '#AD8D95'} stroke-linecap="round" />
			<path d="M6 12H18" stroke={isActive ? '#7A0021' : '#AD8D95'} stroke-linecap="round" />
			<path d="M6 17H18" stroke={isActive ? '#7A0021' : '#AD8D95'} stroke-linecap="round" />
		</svg>
	);
};

export default TripleBar;
