import React from 'react';
import { InvDashboardData } from '../../../api/types/Response';
import MoreDetails from '../../../assets/icons/MoreDetails';

// import { calcGainLoss } from './OverviewUtils';

interface Props {
	data: InvDashboardData;
}

const InvData: React.FC<Props> = ({ data }) => {
	return (
		<>
			<div className="inv-data-header-container">
				<header className="overview-header">Account Value</header>
				<div className="more-details-container">
					<p>more details</p>
					<MoreDetails />
				</div>
			</div>

			<h1 className="acct-bal">
				{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
					data.currAccVal
				)}
			</h1>
		</>
	);
};

export default InvData;
