import React, { useState } from 'react';
import { motion } from 'framer-motion';
import BeatLoader from 'react-spinners/BeatLoader';

import InputField from '../components/AuthFields/InputField';
import { AppState, useSignInInfo } from '../utils/CustomHooks';
import ErrorMessage from '../components/AuthFields/ErrorMessage';
import { logIn } from '../utils/Auth';
import {
	determineEmailError,
	determinePasswordError,
} from '../components/AuthFields/ErrorHandlers';
import { bloatHoverVariants } from '../motions/SignUpControlMotions';
import { AuthMethod } from './Auth';
import { isSignInInfoIncomplete } from './AuthFields/Utils';

interface Props {
	appState: AppState;
	authMethod: AuthMethod;
	setAuthMethod: React.Dispatch<React.SetStateAction<AuthMethod>>;
}

const Login: React.FC<Props> = ({ appState, setAuthMethod }) => {
	// State
	const { signInInfo, setSignInInfo } = useSignInInfo();
	const [inputErrorCount, setInputErrorCount] = useState(0);
	const [isIncompleteError, setIsIncompleteError] = useState(false);
	const [isBadSignIn, setIsBadSignIn] = useState(false);
	const [isSigningIn, setIsSigningIn] = useState(false);

	const signInHandler = async () => {
		if (inputErrorCount > 0 || isSignInInfoIncomplete(signInInfo)) {
			setIsIncompleteError(true);
			return;
		}
		setIsSigningIn(true);
		await logIn(signInInfo.email, signInInfo.password, appState.setCurrUser)
			.then(() => {
				appState.setIsLoggedIn(true);
			})
			.catch(() => {
				setIsSigningIn(false);
				setIsBadSignIn(true);
			});
	};

	const renderEmailPasswordInput = (): JSX.Element => {
		return (
			<>
				<InputField
					title={'Email'}
					value={signInInfo.email}
					type={'email'}
					determineError={determineEmailError}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setSignInInfo((p) => ({ ...p, email: e.target.value }));
						setIsIncompleteError(false);
						setIsBadSignIn(false);
					}}
					setInputErrorCount={setInputErrorCount}
				/>
				<InputField
					title={'Password'}
					value={signInInfo.password}
					type={'password'}
					determineError={determinePasswordError}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setSignInInfo((p) => ({ ...p, password: e.target.value }));
						setIsIncompleteError(false);
						setIsBadSignIn(false);
					}}
					setInputErrorCount={setInputErrorCount}
				/>
			</>
		);
	};

	const renderSignInButton = (): JSX.Element => {
		return (
			<button
				className="sign-in-button"
				onClick={(e) => {
					e.preventDefault();
					signInHandler();
				}}
				disabled={isIncompleteError}
			>
				Sign IN!
			</button>
		);
	};

	return (
		<>
			<div className="left-pane">
				<h1 className="heading">Login.</h1>
				<div className="change-auth-method">
					<h3>Need an account?</h3>
					<motion.button
						variants={bloatHoverVariants}
						whileHover="hover"
						onClick={() => setAuthMethod(AuthMethod.SignUp)}
						disabled={isSigningIn}
					>
						Click Here
					</motion.button>
				</div>
			</div>
			<div className="right-pane">
				<form
					onKeyDown={(e) => {
						if (e.keyCode === 13) {
							signInHandler();
						}
					}}
				>
					<div className="input-fields">{renderEmailPasswordInput()}</div>
				</form>
				{isIncompleteError ? <ErrorMessage message={'One or more fields are incomplete.'} /> : ''}
				{isBadSignIn ? (
					<ErrorMessage message={'Invalid email/password combination! Please try again.'} />
				) : (
					''
				)}
				{isSigningIn ? <BeatLoader color={'#7a0021'} /> : renderSignInButton()}
			</div>
		</>
	);
};

export default Login;
