import React from 'react';
import { v4 as uuid } from 'uuid';
import { AllEquityInfo } from '../../../api/types/Response';
import Equity from './Equity';

interface Props {
	eqInfoList: AllEquityInfo;
}

const EqTableContainer: React.FC<Props> = ({ eqInfoList }) => {
	return (
		<div className="acct-table-container">
			<table>
				<tr className="header-row">
					<th>NAME</th>
					<th>TICKER</th>
					<th>ACTIONS</th>
					<th>RECENT UPDATE</th>
					<th>SHARES OWNED</th>
					<th>POSITION SIZE</th>
				</tr>
				{eqInfoList.map((eqInfo) => (
					<Equity key={uuid()} eqInfo={eqInfo} />
				))}
			</table>
		</div>
	);
};

export default EqTableContainer;
