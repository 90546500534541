import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { Pages } from './Navigation';
import { navLinkHoverVariants } from '../../motions/NavMotions';

interface Props {
	activePage: Pages;
	setActivePage: React.Dispatch<React.SetStateAction<Pages>>;
	myPage: Pages;
	displayName: string;
}

const NavLink: React.FC<Props> = ({ activePage, setActivePage, myPage, displayName }) => {
	return (
		<Link to={myPage} onClick={() => setActivePage(myPage)}>
			<motion.h1
				variants={navLinkHoverVariants}
				whileHover="hover"
				className={activePage === myPage ? 'nav-active' : ''}
			>
				{displayName}
			</motion.h1>
		</Link>
	);
};

export default NavLink;
