import React, { useState } from 'react';
import { AllEquityInfo, AllInvestorInfo } from '../../api/types/Response';
import { AppState } from '../../utils/CustomHooks';

// Components
import AccountingLeftPane from './Accounting/AccountingLeftPane';
import AccountingRightPane from './Accounting/AccountingRightPane';

interface Props {
	appState: AppState;
	invInfoList: AllInvestorInfo | null;
	eqInfoList: AllEquityInfo | null;
}

export enum AccountingTabs {
	Investors,
	Equities,
	VwAccount,
}

const Accounting: React.FC<Props> = ({ appState, invInfoList, eqInfoList }) => {
	const [activeTab, setActiveTab] = useState<AccountingTabs>(AccountingTabs.Investors);

	return (
		<div className="accounting-container">
			<AccountingLeftPane appState={appState} activeTab={activeTab} setActiveTab={setActiveTab} />
			<AccountingRightPane
				activeTab={activeTab}
				invInfoList={invInfoList}
				eqInfoList={eqInfoList}
			/>
		</div>
	);
};

export default Accounting;
