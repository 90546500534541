import React, { useEffect, useMemo, useState } from 'react';

const Typewriter: React.FC = () => {
	const wordBank: string[] = useMemo(() => ['Visionaries', 'Leaders', 'Entrepreneurs'], []);
	const [wordIndex, setWordIndex] = useState<number>(0);
	const [subIndex, setSubIndex] = useState<number>(0);
	const [isDeleting, setIsDeleting] = useState<boolean>(false);

	useEffect(() => {
		if (subIndex === wordBank[wordIndex].length + 1 && !isDeleting) {
			setIsDeleting(true);
			return;
		}
		if (subIndex === 0 && isDeleting) {
			setIsDeleting(false);
			if (wordIndex === wordBank.length - 1) {
				setWordIndex(0);
			} else {
				setWordIndex((prev) => prev + 1);
			}
			return;
		}
		const timeout = setTimeout(() => {
			setSubIndex((prev) => prev + (isDeleting ? -1 : 1));
		}, 300);

		return () => clearTimeout(timeout);
	}, [subIndex, wordIndex, isDeleting, wordBank]);

	return (
		<div className="typewriter-container">
			<h3>
				Investing for Seattle's <br /> next generation of
			</h3>
			<div className="tw-content">
				<h1 className="word">{`${wordBank[wordIndex].substring(0, subIndex)}`}</h1>
				<h1 className="placeholder">.</h1>
			</div>
		</div>
	);
};

export default Typewriter;
