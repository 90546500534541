import React from 'react';
import ErrorIcon from '../../assets/icons/Error';

interface Props {
	message: string;
}

const ErrorMessage: React.FC<Props> = ({ message }) => {
	return (
		<div className="input-error">
			<ErrorIcon /> <div className="message"> {message}</div>
		</div>
	);
};

export default ErrorMessage;
