import React from 'react';

interface Props {
	onClick?: () => any;
}

const WalletDeposit: React.FC<Props> = ({ onClick }) => {
	return (
		<svg
			onClick={onClick ? () => onClick() : undefined}
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M31.7727 4.5H4.22727C2.72104 4.5 1.5 5.84315 1.5 7.5V25.5C1.5 27.1569 2.72104 28.5 4.22727 28.5H21V25.5H4.5V15H31.5V19.5H34.5V7.5C34.5 5.84315 33.279 4.5 31.7727 4.5ZM31.5 12V7.50001H4.5V12H31.5ZM25.5 28.5V25.5H28.5V22.5H31.5V25.5H34.5V28.5H31.5V31.5H28.5V28.5H25.5Z"
				fill="#7A0021"
			/>
		</svg>
	);
};

export default WalletDeposit;
