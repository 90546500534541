import React from 'react';
import { motion } from 'framer-motion';
import { rightHoverVariants } from '../../motions/SignUpControlMotions';

interface Props {
	onClick: () => JSX.Element | undefined;
}

const RightArrow: React.FC<Props> = ({ onClick }) => {
	return (
		<div onClick={onClick} className="right-arrow">
			<motion.svg
				variants={rightHoverVariants}
				whileHover="hover"
				width="48"
				height="36"
				viewBox="0 0 48 36"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M24 30L21.18 27.885L32.34 19.5L8 19.5L8 16.5L32.34 16.5L21.16 8.13L24 6L40 18L24 30Z"
					fill="#7A0021"
				/>
			</motion.svg>
		</div>
	);
};

export default RightArrow;
