import React, { useState } from 'react';

import WalletDeposit from '../../../assets/icons/WalletDeposit';
import CircleDash from '../../../assets/icons/CircleDash';
import Search from '../../../assets/icons/Search';
import Refresh from '../../../assets/icons/Refresh';
import { InvestorData } from '../../../api/types/Response';

interface Props {
	invInfo: InvestorData;
}

enum RowState {
	Closed,
	OpenDeposit,
	OpenWithdrawal,
	InProgress,
}

const Investor: React.FC<Props> = ({ invInfo }) => {
	const [rowState, setRowState] = useState<RowState>(RowState.Closed);

	const style = {
		color: invInfo.isActivated ? '#35b668' : '#fa5656',
	};

	const renderAddTransaction = (): JSX.Element => {
		switch (rowState) {
			case RowState.OpenDeposit:
				return <div className="acct-add-transaction">Lots of deposit stuff</div>;
			case RowState.OpenWithdrawal:
				return <div className="acct-add-transaction">Lots of withdrawal stuff</div>;
			default:
				return <></>;
		}
	};

	return (
		<>
			<tr className="data-row">
				<td>
					{invInfo.fname} {invInfo.lname}
				</td>
				<td>{invInfo.email}</td>
				<td style={style}>{invInfo.isActivated ? 'Activated' : 'Not Online'}</td>
				<td>
					<div className="actions-container">
						<WalletDeposit
							onClick={() =>
								setRowState((prev) =>
									prev === RowState.OpenDeposit ? RowState.Closed : RowState.OpenDeposit
								)
							}
						/>
						<CircleDash
							onClick={() =>
								setRowState((prev) =>
									prev === RowState.OpenWithdrawal ? RowState.Closed : RowState.OpenWithdrawal
								)
							}
						/>
						<Refresh />
					</div>
				</td>
				<td>
					<Search />
				</td>
			</tr>
			{renderAddTransaction()}
		</>
	);
};

export default Investor;
