import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Global styling
import './styles/App.scss';

// Components
import DesktopNav from '../src/components/NavComponents/DesktopNav';
import Home from './pages/Home';
import OurStory from './pages/OurStory';
import AccountContainer from './pages/AccountContainer';
import { useAppState, AppState } from './utils/CustomHooks';
import AuthContainter from './pages/AuthContainer';

const App: React.FC = () => {
	// State
	const appState: AppState = useAppState();

	return (
		<div className="app">
			<DesktopNav appState={appState} />
			<Switch>
				<Route exact path="/">
					<Home appState={appState} />
				</Route>
				<Route exact path="/ourstory">
					<OurStory />
				</Route>
				<Route exact path="/account">
					{appState.isLoggedIn ? <AccountContainer appState={appState} /> : <Redirect to="/" />}
				</Route>
				<Route exact path="/auth">
					{appState.isLoggedIn ? (
						<Redirect to="/account" />
					) : (
						<AuthContainter appState={appState} />
					)}
				</Route>
			</Switch>
		</div>
	);
};

export default App;

export const isLocal: boolean = true;
