import React from 'react';

interface Props {
	isActive: boolean;
}

const PeopleOutline: React.FC<Props> = ({ isActive }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1 18C1 15.75 4 15.75 5.5 14.25C6.25 13.5 4 13.5 4 9.75C4 7.25025 4.99975 6 7 6C9.00025 6 10 7.25025 10 9.75C10 13.5 7.75 13.5 8.5 14.25C10 15.75 13 15.75 13 18"
				stroke={isActive ? '#7A0021' : '#AD8D95'}
				stroke-linecap="round"
			/>
			<path
				d="M11.9948 16.2431C12.5618 15.8523 13.2978 15.571 14.0021 15.2318C14.5528 14.9665 15.0841 14.6659 15.5 14.25C16.25 13.5 14 13.5 14 9.75C14 7.25025 14.9998 6 17 6C19.0003 6 20 7.25025 20 9.75C20 13.5 17.75 13.5 18.5 14.25C20 15.75 23 15.75 23 18"
				stroke={isActive ? '#7A0021' : '#AD8D95'}
				stroke-linecap="round"
			/>
		</svg>
	);
};

export default PeopleOutline;
