import React, { useState } from 'react';

import ErrorMessage from './ErrorMessage';

interface Props {
	title: string;
	placeholder?: string;
	value: string;
	type: 'text' | 'password' | 'email';
	determineError: (inpt: string) => string | null;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	setInputErrorCount: (value: React.SetStateAction<number>) => void;
}

const InputField: React.FC<Props> = ({
	title,
	placeholder,
	value,
	type,
	determineError,
	onChange,
	setInputErrorCount,
}) => {
	const [isErrored, setIsErrored] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>('');

	return (
		<div className="input-row">
			<div className="input-field">
				<p>{title}:</p>
				<input
					onBlur={(e) => {
						const err: string | null = determineError(e.target.value);
						if (err) {
							setIsErrored(true);
							setErrorMessage(err);
							setInputErrorCount((prev) => prev + 1);
						}
					}}
					onFocus={() => {
						if (isErrored) {
							setInputErrorCount((prev) => prev - 1);
							setIsErrored(false);
						}
					}}
					onChange={onChange}
					type={type}
					placeholder={placeholder ? placeholder : ''}
					value={value}
					className={isErrored ? 'error' : ''}
				/>
			</div>
			{isErrored ? <ErrorMessage message={errorMessage} /> : ''}
		</div>
	);
};

export default InputField;
