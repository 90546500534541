import React from 'react';

const VwLogo: React.FC = () => {
	return (
		<svg width="96" height="96" viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M80 48c0 17.673-14.327 32-32 32-17.673 0-32-14.327-32-32 0-17.673 14.327-32 32-32 17.673 0 32 14.327 32 32zm-54.758 0c0 12.569 10.19 22.758 22.758 22.758 12.569 0 22.758-10.19 22.758-22.758 0-12.569-10.19-22.758-22.758-22.758-12.569 0-22.758 10.19-22.758 22.758z"
				fill="#7A0021"
			/>
			<path
				d="M71 48c0 12.703-10.297 23-23 23S25 60.703 25 48s10.297-23 23-23 23 10.297 23 23zm-44.606 0c0 11.933 9.673 21.606 21.606 21.606 11.933 0 21.606-9.673 21.606-21.606 0-11.933-9.673-21.606-21.606-21.606-11.933 0-21.606 9.673-21.606 21.606z"
				fill="#FFD633"
			/>
			<path
				d="M81 48c0 18.225-14.775 33-33 33S15 66.225 15 48s14.775-33 33-33 33 14.775 33 33zm-64.511 0c0 17.403 14.108 31.511 31.511 31.511S79.511 65.403 79.511 48 65.403 16.488 48 16.488 16.488 30.597 16.488 48z"
				fill="#FFD633"
			/>
			<path
				d="M42.403 42.083a.25.25 0 00.063.201l4.969 5.466H2a.25.25 0 00-.033.498l40.116 5.349a.25.25 0 00.201-.063l5.466-4.969V94a.25.25 0 00.498.033l5.349-40.116a.25.25 0 00-.063-.201l-4.969-5.466H94a.25.25 0 00.033-.498l-40.116-5.349a.25.25 0 00-.201.063l-5.466 4.969V2a.25.25 0 00-.498-.033l-5.349 40.116z"
				fill="#7A0021"
				stroke="#FFD633"
				strokeWidth=".5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path stroke="#7A0021" strokeWidth=".5" d="M70.677 44.64l8.725 1.153" />
			<path
				stroke="#7A0021"
				strokeWidth=".55"
				d="M51.373 70.676l-1.15 8.724M44.627 25.314l1.149-8.725M25.334 51.373l-8.725-1.149"
			/>
			<path
				stroke="#FFD633"
				strokeWidth=".5"
				d="M16.2 47.25h9M16.2 47.25h9M70.9 48.75h9M47.3 79.9v-9M48.75 25.05v-9"
			/>
		</svg>
	);
};

export default VwLogo;
