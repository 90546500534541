import React from 'react';

interface Props {
	onClick?: () => any;
}

const CircleDash: React.FC<Props> = ({ onClick }) => {
	return (
		<svg
			onClick={onClick ? () => onClick() : undefined}
			width="36"
			height="36"
			viewBox="0 0 36 36"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M18 34.5C8.8873 34.5 1.5 27.1127 1.5 18C1.5 8.8873 8.8873 1.5 18 1.5C27.1127 1.5 34.5 8.8873 34.5 18C34.5 27.1127 27.1127 34.5 18 34.5ZM18 31.5C25.4558 31.5 31.5 25.4558 31.5 18C31.5 10.5442 25.4558 4.5 18 4.5C10.5442 4.5 4.5 10.5442 4.5 18C4.5 25.4558 10.5442 31.5 18 31.5ZM10.5 16.5V19.5H25.5V16.5H10.5Z"
				fill="#7A0021"
			/>
		</svg>
	);
};

export default CircleDash;
