import React from 'react';

const Withdrawal: React.FC = () => {
	return (
		<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0)">
				<path
					d="M35.4583 46C35.213 46 34.9676 45.9061 34.7798 45.7202L25.1965 36.1368C24.9224 35.8628 24.84 35.4507 24.9895 35.0922C25.139 34.7338 25.4878 34.5 25.875 34.5H30.6666V24.9167C30.6666 23.8606 31.5253 23 32.5833 23H38.3333C39.3913 23 40.25 23.8606 40.25 24.9167V34.5H45.0417C45.4288 34.5 45.7796 34.7338 45.9272 35.0922C46.0747 35.4507 45.9923 35.8628 45.7202 36.1368L36.1368 45.7202C35.949 45.9061 35.7036 46 35.4583 46ZM28.1884 36.4167L35.4583 43.6866L42.7282 36.4167H39.2916C38.7626 36.4167 38.3333 35.9873 38.3333 35.4583V24.9167H32.5833V35.4583C32.5833 35.9873 32.154 36.4167 31.625 36.4167H28.1884Z"
					fill="#CE0C0C"
				/>
				<path
					d="M22.0417 40.25H4.79167C2.14858 40.25 0 38.0995 0 35.4583V10.5417C0 7.9005 2.14858 5.75 4.79167 5.75H41.2083C43.8514 5.75 46 7.9005 46 10.5417V29.7083C46 30.2373 45.5707 30.6667 45.0417 30.6667C44.5127 30.6667 44.0833 30.2373 44.0833 29.7083V10.5417C44.0833 8.95658 42.7934 7.66667 41.2083 7.66667H4.79167C3.20658 7.66667 1.91667 8.95658 1.91667 10.5417V35.4583C1.91667 37.0434 3.20658 38.3333 4.79167 38.3333H22.0417C22.5707 38.3333 23 38.7627 23 39.2917C23 39.8207 22.5707 40.25 22.0417 40.25Z"
					fill="#CE0C0C"
				/>
				<path
					d="M14.375 30.6668H0.958333C0.429333 30.6668 0 30.2375 0 29.7085V16.2918C0 15.7628 0.429333 15.3335 0.958333 15.3335H14.375C17.0181 15.3335 19.1667 17.484 19.1667 20.1252V25.8752C19.1667 28.5163 17.0181 30.6668 14.375 30.6668ZM1.91667 28.7502H14.375C15.9601 28.7502 17.25 27.4602 17.25 25.8752V20.1252C17.25 18.5401 15.9601 17.2502 14.375 17.2502H1.91667V28.7502Z"
					fill="#CE0C0C"
				/>
				<path
					d="M9.58333 26.8332C7.46925 26.8332 5.75 25.1139 5.75 22.9999C5.75 20.8858 7.46925 19.1665 9.58333 19.1665C11.6974 19.1665 13.4167 20.8858 13.4167 22.9999C13.4167 25.1139 11.6974 26.8332 9.58333 26.8332ZM9.58333 21.0832C8.52533 21.0832 7.66666 21.9438 7.66666 22.9999C7.66666 24.0559 8.52533 24.9165 9.58333 24.9165C10.6413 24.9165 11.5 24.0559 11.5 22.9999C11.5 21.9438 10.6413 21.0832 9.58333 21.0832Z"
					fill="#CE0C0C"
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="46" height="46" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Withdrawal;
