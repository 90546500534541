import React from 'react';

const Refresh: React.FC = () => {
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M26.7104 10.5C24.6697 7.61804 21.5904 6 18 6C11.3726 6 6 11.3726 6 18C6 24.6274 11.3726 30 18 30C24.6274 30 30 24.6274 30 18H33C33 26.2843 26.2843 33 18 33C9.71573 33 3 26.2843 3 18C3 9.71573 9.71573 3 18 3C22.2064 3 25.9123 4.77377 28.5 7.90552V3H31.5V13.5H21V10.5H26.7104Z"
				fill="#7A0021"
			/>
		</svg>
	);
};

export default Refresh;
