import React, { useState } from 'react';
import { motion } from 'framer-motion';

import InputField from '../components/AuthFields/InputField';
import SignUpControl from '../components/SignUpControl';
import { AppState, useCollectSignUpInfo } from '../utils/CustomHooks';
import ErrorMessage from '../components/AuthFields/ErrorMessage';
import {
	determineConfPasswordError,
	determineEmailError,
	determineFnameError,
	determineLnameError,
	determinePasswordError,
	determinePhoneNumError,
} from '../components/AuthFields/ErrorHandlers';
import { handlePhoneChange } from '../components/AuthFields/Utils';
import { bloatHoverVariants } from '../motions/SignUpControlMotions';
import { AuthMethod } from './Auth';

interface Props {
	appState: AppState;
	authMethod: AuthMethod;
	setAuthMethod: React.Dispatch<React.SetStateAction<AuthMethod>>;
}

export enum InputFieldState {
	NameInfo,
	PhoneNumber,
	EmailPassword,
}

const CreateAccount: React.FC<Props> = ({ appState, authMethod, setAuthMethod }) => {
	// State
	const { signUpInfo, setSignUpInfo } = useCollectSignUpInfo();
	const [inputFieldState, setInputFieldState] = useState<InputFieldState>(InputFieldState.NameInfo);
	const [inputErrorCount, setInputErrorCount] = useState<number>(0);
	const [isIncompleteError, setIsIncompleteError] = useState(false);
	const [isEmailPreviouslyUsed, setIsEmailPreviouslyUsed] = useState(false);
	const [isSigningUp, setIsSigningUp] = useState(false);

	// Functions
	const renderNameInput = (): JSX.Element => {
		return (
			<>
				<InputField
					title={'First Name'}
					value={signUpInfo.fname}
					type={'text'}
					determineError={determineFnameError}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setSignUpInfo((p) => ({ ...p, fname: e.target.value }));
						setIsIncompleteError(false);
					}}
					setInputErrorCount={setInputErrorCount}
				/>
				<InputField
					title={'Last Name'}
					value={signUpInfo.lname}
					type={'text'}
					determineError={determineLnameError}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setSignUpInfo((p) => ({ ...p, lname: e.target.value }));
						setIsIncompleteError(false);
					}}
					setInputErrorCount={setInputErrorCount}
				/>
			</>
		);
	};

	const renderPhoneInput = (): JSX.Element => {
		return (
			<InputField
				title={'Phone Number'}
				value={signUpInfo.phoneNumber}
				type={'text'}
				determineError={determinePhoneNumError}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					const phoneNumber = handlePhoneChange(e);
					if (phoneNumber) {
						setSignUpInfo((p) => ({ ...p, phoneNumber }));
						setIsIncompleteError(false);
					}
				}}
				setInputErrorCount={setInputErrorCount}
			/>
		);
	};

	const renderEmailPasswordInput = (): JSX.Element => {
		return (
			<>
				<InputField
					title={'Email'}
					value={signUpInfo.email}
					type={'email'}
					determineError={determineEmailError}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setSignUpInfo((p) => ({ ...p, email: e.target.value }));
						setIsIncompleteError(false);
						setIsEmailPreviouslyUsed(false);
					}}
					setInputErrorCount={setInputErrorCount}
				/>
				<InputField
					title={'Password'}
					value={signUpInfo.password}
					type={'password'}
					determineError={determinePasswordError}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setSignUpInfo((p) => ({ ...p, password: e.target.value }));
						setIsIncompleteError(false);
					}}
					setInputErrorCount={setInputErrorCount}
				/>
				<InputField
					title={'Confirm Password'}
					value={signUpInfo.confPassword}
					type={'password'}
					determineError={(inpt: string) => determineConfPasswordError(inpt, signUpInfo.password)}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setSignUpInfo((p) => ({ ...p, confPassword: e.target.value }));
						setIsIncompleteError(false);
					}}
					setInputErrorCount={setInputErrorCount}
				/>
			</>
		);
	};

	const renderSignUpFields = (): JSX.Element => {
		switch (inputFieldState) {
			case InputFieldState.NameInfo:
				return renderNameInput();
			case InputFieldState.PhoneNumber:
				return renderPhoneInput();
			case InputFieldState.EmailPassword:
				return renderEmailPasswordInput();
			default:
				return <></>;
		}
	};

	return (
		<>
			<div className="left-pane">
				<h1 className="heading">Create an account.</h1>
				<div className="change-auth-method">
					<h3>Already have an account?</h3>
					<motion.button
						variants={bloatHoverVariants}
						whileHover="hover"
						onClick={() => setAuthMethod(AuthMethod.Login)}
						disabled={isSigningUp}
					>
						Click Here
					</motion.button>
				</div>
			</div>
			<div className="right-pane">
				<form>
					<div className="input-fields">{renderSignUpFields()}</div>
				</form>
				{isIncompleteError ? <ErrorMessage message={'One or more fields are incomplete.'} /> : ''}
				{isEmailPreviouslyUsed ? (
					<ErrorMessage
						message={'Unable to sign up! This email has already been used to make an account.'}
					/>
				) : (
					''
				)}
				<SignUpControl
					inputFieldState={inputFieldState}
					setInputFieldState={setInputFieldState}
					appState={appState}
					signUpInfo={signUpInfo}
					inputErrorCount={inputErrorCount}
					setInputErrorCount={setInputErrorCount}
					isIncompleteError={isIncompleteError}
					setIsIncompleteError={setIsIncompleteError}
					isSigningUp={isSigningUp}
					setIsSigningUp={setIsSigningUp}
					setIsEmailPreviouslyUsed={setIsEmailPreviouslyUsed}
				/>
			</div>
		</>
	);
};

export default CreateAccount;
