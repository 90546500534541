import React from 'react';

import skyline from '../assets/images/SkylineBanner.jpg';
import lakeside from '../assets/images/Lakeside1.jpg';
import ranier from '../assets/images/MtRanier.jpg';
import isaiah from '../assets/images/IsaiahBio.jpg';
import isiah from '../assets/images/IsiahBio.jpg';
import aaron from '../assets/images/AaronBio.jpg';
import alec from '../assets/images/AlecBio.jpg';

const OurStory: React.FC = () => {
	return (
		<div className="our-story-container">
			<div className="banner-image">
				<img src={skyline} alt="Skyline Banner" />
				<h1 className="text-overlay">OUR STORY</h1>
			</div>
			<div className="section-one">
				<div className="image">
					<img src={lakeside} alt="Walkway at Lakeside School" />
				</div>
				<div className="text">
					<h1>Lakeside Born</h1>
					<p>
						The vision for Village West was born out of a friendship forged under the watchful eyes
						of Bliss Hall on the campus of Lakeside School. Each of the four VW founders are proud
						Lakeside alumni and believe in establishing a culture, not unlike Lakeside's, based on
						the core values of lifelong learning, hard work, and humility. With those tenets in
						mind, we strive to empower our peers, who are just beginning their journeys into
						adulthood, through expanding financial literacy with educational content, growing
						personal wealth with equity investing, and cultivating a community of support. In other
						words, investing with VW opens much more than just a door to financial independence; we
						want to help propel our peers towards their ultimate lifestyle.
					</p>
				</div>
				<div className="text">
					<h1>Community Driven</h1>
					<p>
						Simply put, the passion we bring every day to improving VW comes from the desire to
						empower our peers. As a group, we've made a collective commitment to give back to the
						community which brought us up and has given us opportunities. The community of VW will
						celebrate each other's successes as well as lift those up who need a boost; we believe
						that one community member's success is everyone's. So, while everyone may not physically
						be in the same place, every member will always feel supported by the entire group.
					</p>
				</div>
				<div className="image">
					<img src={ranier} alt="Walkway at Lakeside School" />
				</div>
			</div>
			<div className="horiz-divider"></div>
			<div className="section-two">
				<h1>Meet Our Family:</h1>
				<div className="bios">
					<div className="idzay">
						<img src={isaiah} alt="Isaiah D bio" />
						<h3>Isaiah D.</h3>
					</div>
					<div className="ozay">
						<img src={isiah} alt="Isiah B bio" />
						<h3>Isiah B.</h3>
					</div>
					<div className="frozone">
						<img src={alec} alt="Alec M bio" />
						<h3>Alec M.</h3>
					</div>
					<div className="speedy">
						<img src={aaron} alt="Aaron M bio" />
						<h3>Aaron M.</h3>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OurStory;
