import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { InvPerformance } from '../../../api/types/Response';

interface Props {
	data: InvPerformance;
}

type HighchartsData = [number, number][];

const InvPerf: React.FC<Props> = ({ data }) => {
	const highchartsData: HighchartsData = data.map((obs) => [Date.parse(obs.x), obs.y]);

	const renderTable = (): JSX.Element => {
		console.log({ data });
		const options: Highcharts.Options = {
			chart: {
				backgroundColor: 'rgba(255, 255, 255, 0.01)',
				type: 'line',
			},
			rangeSelector: {
				selected: 1,
				inputStyle: {
					color: '#7a0021',
				},
			},
			colors: ['#7a0021'],
			series: [
				{
					type: 'spline',
					data: highchartsData,
					tooltip: {
						valueDecimals: 2,
						valuePrefix: '$',
					},
				},
			],
		};

		return (
			<HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={options} />
		);
	};

	const renderPerfSection = (): JSX.Element => {
		return (
			<>
				<header className="overview-header">Performance:</header>
				<div className="perf-chart-wrapper">{renderTable()}</div>
			</>
		);
	};

	return data.length > 0 ? renderPerfSection() : <div>Spinner!</div>;
};

export default InvPerf;
