import { VwApiClient } from '../Client';
import { VwApiEndpoint } from '../types/Endpoints';
import { BasicParams } from '../types/Params';
import { AllEquityInfo } from '../types/Response';

export const getAllEquitiesInfo = async (params: BasicParams): Promise<AllEquityInfo> => {
	return VwApiClient.makeRequest<BasicParams, AllEquityInfo>(
		VwApiEndpoint.GetAllEquitiesInfo,
		'get',
		params
	);
};
