import React from 'react';
import { v4 as uuid } from 'uuid';
import { AllInvestorInfo } from '../../../api/types/Response';

import Investor from './Investor';

interface Props {
	invInfoList: AllInvestorInfo;
}

const InvTableContainer: React.FC<Props> = ({ invInfoList }) => {
	return (
		<div className="acct-table-container">
			<table>
				<tr className="header-row">
					<th>NAME</th>
					<th>EMAIL</th>
					<th>STATUS</th>
					<th>ACTIONS</th>
					<th>MORE INFO</th>
				</tr>
				{invInfoList.map((invInfo) => (
					<Investor key={uuid()} invInfo={invInfo} />
				))}
			</table>
		</div>
	);
};

export default InvTableContainer;
