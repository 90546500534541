import React, { useEffect, useState } from 'react';

// Components
import Overview from '../components/MyAccount/Overview';
import Accounting from '../components/MyAccount/Accounting';
import { AppState } from '../utils/CustomHooks';
import Reports from '../components/MyAccount/Reports';
import { BasicParams, InvestorBasicParams } from '../api/types/Params';
import {
	AllEquityInfo,
	AllInvestorInfo,
	InvDashboardData,
	InvPerformance,
	InvTransactionsList,
} from '../api/types/Response';
import {
	getAllInvestorsInfo,
	getInvestorDashboardData,
	getInvestorPerformance,
	getInvestorTransactions,
} from '../api/methods/Investor';
import { getAllEquitiesInfo } from '../api/methods/Equity';

enum AccountTab {
	Overview,
	Reports,
	Accounting,
}

interface Props {
	appState: AppState;
}

const AccountContainer: React.FC<Props> = ({ appState }) => {
	// State
	const [accountTab, setAccountTab] = useState(AccountTab.Overview);
	const [invId, setInvId] = useState<string | null>(null);
	const [authID, setAuthID] = useState<string>('');

	/* State for an investor dashboard */
	const [invDashboardData, setInvDashboardData] = useState<InvDashboardData | null>(null);
	const [invPerfData, setInvPerfData] = useState<InvPerformance | null>(null);
	const [invTransactionsData, setInvTransactionsData] = useState<InvTransactionsList | null>(null);

	/* State for an administrators dashboard */
	const [invInfoList, setInvInfoList] = useState<AllInvestorInfo | null>(null);
	const [eqInfoList, setEqInfoList] = useState<AllEquityInfo | null>(null);

	const [isAdmin, setIsAdmin] = useState<boolean>(false);

	useEffect(() => {
		if (appState.currUser.data && appState.currUser.userInfo) {
			setAuthID(appState.currUser.data.id);
			setInvId(appState.currUser.userInfo.invId);
			if (appState.currUser.userInfo.isAdmin) {
				setIsAdmin(true);
			}
		}
		const db: string = 'accounting';
		if (invId && authID) {
			const params: InvestorBasicParams = { db, invId };
			getInvestorDashboardData(params).then((res) => {
				setInvDashboardData(res);
			});
			getInvestorPerformance(params).then((res) => {
				setInvPerfData(res);
			});
			getInvestorTransactions(params).then((res) => {
				setInvTransactionsData(res);
			});

			if (isAdmin) {
				const params: BasicParams = { db };
				getAllInvestorsInfo(params).then((res) => {
					setInvInfoList(res);
				});
				getAllEquitiesInfo(params).then((res) => {
					setEqInfoList(res);
				});
			}
		}
	}, [appState.currUser.data, appState.currUser.userInfo, authID, invId, isAdmin]);

	// Rendering Functions
	const renderAccountContent = (): JSX.Element => {
		switch (accountTab) {
			case AccountTab.Overview:
				return (
					<Overview
						invId={invId}
						invData={invDashboardData}
						perfData={invPerfData}
						transactionsList={invTransactionsData}
					/>
				);
			case AccountTab.Accounting:
				return <Accounting appState={appState} invInfoList={invInfoList} eqInfoList={eqInfoList} />;
			case AccountTab.Reports:
				return <Reports />;
			default:
				return <></>;
		}
	};

	const renderAccountingTab = (): JSX.Element => {
		return (
			<h3
				onClick={() => setAccountTab(AccountTab.Accounting)}
				className={accountTab === AccountTab.Accounting ? 'acc-active' : 'acc-non-active'}
			>
				Accounting
			</h3>
		);
	};

	return (
		<div className="my-account-wrapper">
			<div className="header-wrapper">
				<h3
					onClick={() => setAccountTab(AccountTab.Overview)}
					className={accountTab === AccountTab.Overview ? 'acc-active' : 'acc-non-active'}
				>
					Overview
				</h3>
				{isAdmin ? renderAccountingTab() : ''}
				<h3
					onClick={() => setAccountTab(AccountTab.Reports)}
					className={accountTab === AccountTab.Reports ? 'acc-active' : 'acc-non-active'}
				>
					Quarterly Reports
				</h3>
			</div>
			{renderAccountContent()}
		</div>
	);
};

export default AccountContainer;
