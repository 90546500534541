import GoTrue, { User } from 'gotrue-js';

import { stripExtraCharacters } from '../components/AuthFields/Utils';
import { confirmOnlineUser, getSingleOnlineUserInfo } from '../api/methods/WebApp';
import { ConfirmOnlineUserParmas, OnlineUserParams } from '../api/types/Params';
import { AuthenticatedUser } from '../api/types/Response';

// Our Netlify Identity API url (not secret)
const APIUrl: string = 'https://www.villagewest.co/.netlify/identity';

// The authorization object provided by gotrue API
export const authClient: GoTrue = new GoTrue({
	APIUrl,
});

export enum AuthStatus {
	Unstarted,
	Pending,
	Finished,
	Errored,
}

export interface AuthAction {
	status: AuthStatus;
	payload: User | null;
}

export interface SignInInfo {
	email: string;
	password: string;
}

export interface SignUpInfo extends SignInInfo {
	confPassword: string;
	fname: string;
	lname: string;
	phoneNumber: string;
}

export interface VwUser {
	userInfo: AuthenticatedUser | null;
	data: User | null;
}

const confirmUser = async (params: ConfirmOnlineUserParmas) => {
	return await confirmOnlineUser(params);
};

const buildConfirmInvParams = (info: SignUpInfo, authID: string): ConfirmOnlineUserParmas => {
	return {
		db: 'accounting',
		onlineId: authID,
		fname: info.fname,
		lname: info.lname,
		email: info.email,
		phoneNumber: Number(stripExtraCharacters(info.phoneNumber)),
	};
};

export const signUp = async (info: SignUpInfo): Promise<AuthStatus> => {
	return await authClient
		.signup(info.email, info.password)
		.then(async (res) => {
			await confirmUser(buildConfirmInvParams(info, res.id)).catch((err) => console.error(err));
			return Promise.resolve(AuthStatus.Finished);
		})
		.catch(() => {
			return Promise.reject(AuthStatus.Errored);
		});
};

export const logIn = async (
	email: string,
	password: string,
	setCurrUser: React.Dispatch<React.SetStateAction<VwUser>>
) => {
	// Need to get user information from our database
	return await authClient
		.login(email, password)
		.then(async (data) => {
			const params: OnlineUserParams = {
				db: 'accounting',
				onlineId: data.id,
			};
			await getSingleOnlineUserInfo(params).then((userInfo) => {
				setCurrUser({ data, userInfo });
			});
		})
		.catch((err) => {
			return Promise.reject(err);
		});
};

export const logOut = async (): Promise<void> => {
	const user: User | null = authClient.currentUser();
	if (user) {
		return await user
			.logout()
			.then((res) => {
				return Promise.resolve();
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	}
};
