type repeatType = 'reverse' | 'loop' | 'mirror' | undefined;

const hoverRepeat: repeatType = 'reverse';
const transDist: number = 25;
const hoverDuration: number = 1;

export const rightHoverVariants = {
	hover: {
		translateX: transDist,
		scale: 1.2,
		transition: {
			duration: hoverDuration,
			repeat: Infinity,
			repeatType: hoverRepeat,
		},
	},
};

export const leftHoverVariants = {
	hover: {
		translateX: -transDist,
		scale: 1.2,
		transition: {
			duration: hoverDuration,
			repeat: Infinity,
			repeatType: hoverRepeat,
		},
	},
};

const fieldDuration: number = 0.7;

export const inputFieldEntryVariants = {
	hidden: {
		opacity: 0,
		x: '10vw',
		transition: {
			duration: fieldDuration,
			type: 'spring',
		},
	},
	visible: {
		opacity: 1,
		x: 0,
		transition: {
			duration: fieldDuration,
			type: 'spring',
		},
	},
};

export const bloatHoverVariants = {
	hover: {
		scale: 1.2,
		transition: {
			duration: 0.3,
		},
	},
};
