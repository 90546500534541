import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { AppState } from '../../utils/CustomHooks';
import { bloatHoverVariants } from '../../motions/NavMotions';
import LearnMoreButton, { ButtonStyles } from '../LearnMoreButton';

interface Props {
	appState: AppState;
	logoutHandler: () => void;
}

const AccountHeader: React.FC<Props> = ({ appState, logoutHandler }) => {
	const { currUser, isLoggedIn } = appState;

	const renderSignedOutActions = (): JSX.Element => {
		return (
			<>
				<Link to="/auth">
					<motion.h1 variants={bloatHoverVariants} whileHover="hover">
						Log In
					</motion.h1>
				</Link>
				<LearnMoreButton style={ButtonStyles.YellowBackground} />
			</>
		);
	};

	const renderSignedInActions = (): JSX.Element | undefined | null => {
		return (
			currUser &&
			currUser.userInfo && (
				<>
					<motion.h1
						variants={bloatHoverVariants}
						whileHover="hover"
						onClick={() => logoutHandler()}
					>
						Sign Out
					</motion.h1>

					<motion.button variants={bloatHoverVariants} whileHover="hover">
						Hi, {currUser.userInfo.fname}!
					</motion.button>
				</>
			)
		);
	};

	return (
		<div className="account-header-container">
			{isLoggedIn ? renderSignedInActions() : renderSignedOutActions()}
		</div>
	);
};

export default AccountHeader;
