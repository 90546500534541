import React from 'react';
import { SignInInfo, SignUpInfo } from '../../utils/Auth';

export interface SignUpFieldProps {
	setSignUpInfo: (value: React.SetStateAction<SignUpInfo>) => void;
	signUpInfo: SignUpInfo;
	setInputErrorCount: React.Dispatch<React.SetStateAction<number>>;
}

export const isSignUpInfoIncomplete = (info: SignUpInfo): boolean => {
	return !(
		info.fname !== '' &&
		info.lname !== '' &&
		info.phoneNumber !== '' &&
		info.email !== '' &&
		info.password !== '' &&
		info.confPassword !== ''
	);
};

export const isSignInInfoIncomplete = (info: SignInInfo): boolean => {
	return !(info.email !== '' && info.password !== '');
};

export const stripExtraCharacters = (formatted: string): string => {
	return formatted.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '');
};

export const prettifyPhone = (oldWithoutChars: string): string => {
	// If we don't have 3 numbers, no need to do any formatting
	if (oldWithoutChars.length <= 3) {
		return oldWithoutChars;
	}

	const length: number = oldWithoutChars.length;

	if (3 < length && length < 7) {
		return `(${oldWithoutChars.substring(0, 3)}) ${oldWithoutChars.substring(3, length)}`;
	} else if (6 < length && length < 11) {
		return `(${oldWithoutChars.substring(0, 3)}) ${oldWithoutChars.substring(
			3,
			6
		)}-${oldWithoutChars.substring(6, length)}`;
	} else {
		return `(${oldWithoutChars.substring(0, 3)}) ${oldWithoutChars.substring(
			3,
			6
		)}-${oldWithoutChars.substring(6, 10)}`;
	}
};

export const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>): string | null => {
	const oldWithoutChars = stripExtraCharacters(e.target.value);
	const newestChar = oldWithoutChars[oldWithoutChars.length - 1];
	return ('0' <= newestChar && newestChar <= '9') || oldWithoutChars.length === 0
		? prettifyPhone(oldWithoutChars)
		: null;
};
