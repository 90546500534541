import React from 'react';
import { motion } from 'framer-motion';

import NavLink from './NavLink';
import { navLinkHoverVariants } from '../../motions/NavMotions';
import { AppState } from '../../utils/CustomHooks';

export enum Pages {
	Home = '/',
	OurStory = '/ourstory',
	MyAccount = '/account',
}

export interface Props {
	appState: AppState;
}

const Navigation: React.FC<Props> = ({ appState }) => {
	const { activePage, setActivePage, isLoggedIn } = appState;
	return (
		<div className="navigation">
			<NavLink
				activePage={activePage}
				setActivePage={setActivePage}
				myPage={Pages.Home}
				displayName="Home"
			/>
			<a href="https://villagewest.imprint.to/">
				<motion.h1 variants={navLinkHoverVariants} whileHover="hover">
					Research
				</motion.h1>
			</a>
			<NavLink
				activePage={activePage}
				setActivePage={setActivePage}
				myPage={Pages.OurStory}
				displayName="Our Story"
			/>
			{isLoggedIn && (
				<NavLink
					activePage={activePage}
					setActivePage={setActivePage}
					myPage={Pages.MyAccount}
					displayName="My Account"
				/>
			)}
		</div>
	);
};

export default Navigation;
