import React from 'react';

export enum ButtonStyles {
	YellowBackground,
	MaroonBackground,
}

interface Props {
	style: ButtonStyles;
}

const LearnMoreButton: React.FC<Props> = ({ style }) => {
	return (
		<a
			href="/ourstory"
			className={
				style === ButtonStyles.YellowBackground
					? 'learn-more-button lmb-y'
					: 'learn-more-button lmb-m'
			}
		>
			Learn More
		</a>
	);
};

export default LearnMoreButton;
