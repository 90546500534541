import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import { InputFieldState } from './CreateAccount';
import RightArrow from '../assets/icons/RightArrow';
import LeftArrow from '../assets/icons/LeftArrow';
import { AppState } from '../utils/CustomHooks';
import { SignUpInfo, signUp, logIn } from '../utils/Auth';
import { isSignUpInfoIncomplete } from './AuthFields/Utils';

interface Props {
	inputFieldState: InputFieldState;
	setInputFieldState: React.Dispatch<React.SetStateAction<InputFieldState>>;
	appState: AppState;
	signUpInfo: SignUpInfo;
	inputErrorCount: number;
	setInputErrorCount: React.Dispatch<React.SetStateAction<number>>;
	isIncompleteError: boolean;
	setIsIncompleteError: React.Dispatch<React.SetStateAction<boolean>>;
	isSigningUp: boolean;
	setIsSigningUp: React.Dispatch<React.SetStateAction<boolean>>;
	setIsEmailPreviouslyUsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignUpControl: React.FC<Props> = ({
	inputFieldState,
	setInputFieldState,
	appState,
	signUpInfo,
	inputErrorCount,
	isIncompleteError,
	setIsIncompleteError,
	isSigningUp,
	setIsSigningUp,
	setIsEmailPreviouslyUsed,
}) => {
	const { setCurrUser, setIsLoggedIn } = appState;

	const signUpHandler = async () => {
		if (inputErrorCount > 0 || isSignUpInfoIncomplete(signUpInfo)) {
			setIsIncompleteError(true);
			return;
		}
		setIsSigningUp(true);
		await signUp(signUpInfo)
			.then(async () => {
				await logIn(signUpInfo.email, signUpInfo.password, setCurrUser)
					.then(() => {
						setIsLoggedIn(true);
					})
					.catch(() => console.error('Couldnt log in the user'));
			})
			.catch(() => {
				setIsSigningUp(false);
				setIsEmailPreviouslyUsed(true);
			});
	};

	const rightProgressHandler = () => {
		if (inputErrorCount > 0 || isSigningUp) {
			return;
		}

		switch (inputFieldState) {
			case InputFieldState.NameInfo:
				setInputFieldState(InputFieldState.PhoneNumber);
				break;
			case InputFieldState.PhoneNumber:
				setInputFieldState(InputFieldState.EmailPassword);
				break;
			case InputFieldState.EmailPassword:
				break;
			default:
				return <></>;
		}
	};

	const leftProgressHandler = () => {
		if (inputErrorCount > 0 || isSigningUp) {
			return;
		}
		switch (inputFieldState) {
			case InputFieldState.NameInfo:
				break;
			case InputFieldState.PhoneNumber:
				setInputFieldState(InputFieldState.NameInfo);
				break;
			case InputFieldState.EmailPassword:
				setInputFieldState(InputFieldState.PhoneNumber);
				break;
			default:
				return <></>;
		}
	};

	const renderSignUpButton = (): JSX.Element => {
		return (
			<button
				className="sign-up-button"
				onClick={(e) => {
					e.preventDefault();
					signUpHandler();
				}}
				disabled={isIncompleteError}
			>
				Sign UP!
			</button>
		);
	};

	const renderControl = (): JSX.Element => {
		switch (inputFieldState) {
			case InputFieldState.NameInfo:
				return <RightArrow onClick={rightProgressHandler} />;
			case InputFieldState.PhoneNumber:
				return (
					<>
						<LeftArrow onClick={leftProgressHandler} />{' '}
						<RightArrow onClick={rightProgressHandler} />
					</>
				);
			case InputFieldState.EmailPassword:
				return (
					<>
						<LeftArrow onClick={leftProgressHandler} />
						{isSigningUp ? <BeatLoader color={'#7a0021'} /> : renderSignUpButton()}
					</>
				);
			default:
				return <></>;
		}
	};
	return <div className="signUpControl">{renderControl()}</div>;
};

export default SignUpControl;
