import React from 'react';
import { InvTransObs } from '../../../api/types/Response';
import Deposit from '../../../assets/icons/Deposit';
import Withdrawal from '../../../assets/icons/Withdrawal';

interface Props {
	transaction: InvTransObs;
}

const Transaction: React.FC<Props> = ({ transaction }) => {
	return (
		<tr className={transaction.isDeposit ? 'transaction deposit' : 'transaction withdrawal'}>
			<td className="trans t-icon">{transaction.isDeposit ? <Deposit /> : <Withdrawal />}</td>
			<td className="trans t-date">
				{new Date(transaction.date).toLocaleString('en', { month: 'short', day: 'numeric' })}
			</td>
			<td className="trans t-amt">
				{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
					transaction.amount
				)}
			</td>
		</tr>
	);
};

export default Transaction;
