import React from 'react';

const Deposit: React.FC = () => {
	return (
		<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0)">
				<path
					d="M38.3332 46H32.5833C31.5272 46 30.6666 45.1394 30.6666 44.0833V34.5H25.875C25.4878 34.5 25.137 34.2661 24.9895 33.9077C24.84 33.5493 24.9224 33.1372 25.1965 32.8631L34.7797 23.2798C35.1535 22.9061 35.7611 22.9061 36.1348 23.2798L45.7181 32.8631C45.9922 33.1372 46.0746 33.5493 45.9251 33.9077C45.7794 34.2661 45.4287 34.5 45.0415 34.5H40.2499V44.0833C40.2499 45.1394 39.3893 46 38.3332 46ZM28.1884 32.5833H31.6249C32.1539 32.5833 32.5833 33.0126 32.5833 33.5416V44.0833H38.3332V33.5416C38.3332 33.0126 38.7626 32.5833 39.2915 32.5833H42.7281L35.4582 25.3134L28.1884 32.5833Z"
					fill="#258005"
				/>
				<path
					d="M25.875 40.25H4.79167C2.1505 40.25 0 38.0995 0 35.4583V10.5417C0 7.9005 2.1505 5.75 4.79167 5.75H41.2083C43.8495 5.75 46 7.9005 46 10.5417V27.7917C46 28.3207 45.5707 28.75 45.0417 28.75C44.5127 28.75 44.0833 28.3207 44.0833 27.7917V10.5417C44.0833 8.95658 42.7934 7.66667 41.2083 7.66667H4.79167C3.20658 7.66667 1.91667 8.95658 1.91667 10.5417V35.4583C1.91667 37.0434 3.20658 38.3333 4.79167 38.3333H25.875C26.404 38.3333 26.8333 38.7627 26.8333 39.2917C26.8333 39.8207 26.404 40.25 25.875 40.25Z"
					fill="#258005"
				/>
				<path
					d="M14.375 30.6668H0.958333C0.429333 30.6668 0 30.2375 0 29.7085V16.2918C0 15.7628 0.429333 15.3335 0.958333 15.3335H14.375C17.0162 15.3335 19.1667 17.484 19.1667 20.1252V25.8752C19.1667 28.5163 17.0162 30.6668 14.375 30.6668ZM1.91667 28.7502H14.375C15.9601 28.7502 17.25 27.4602 17.25 25.8752V20.1252C17.25 18.5401 15.9601 17.2502 14.375 17.2502H1.91667V28.7502Z"
					fill="#258005"
				/>
				<path
					d="M9.58333 26.8332C7.46925 26.8332 5.75 25.1139 5.75 22.9998C5.75 20.8858 7.46925 19.1665 9.58333 19.1665C11.6974 19.1665 13.4167 20.8858 13.4167 22.9998C13.4167 25.1139 11.6974 26.8332 9.58333 26.8332ZM9.58333 21.0832C8.52725 21.0832 7.66667 21.9437 7.66667 22.9998C7.66667 24.0559 8.52725 24.9165 9.58333 24.9165C10.6394 24.9165 11.5 24.0559 11.5 22.9998C11.5 21.9437 10.6394 21.0832 9.58333 21.0832Z"
					fill="#258005"
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="46" height="46" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Deposit;
