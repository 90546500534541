import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import VwLogo from '../../assets/icons/VwLogo';
import { bloatHoverVariants } from '../../motions/NavMotions';

interface Props {
	onClick: () => void;
}

const LogoTitle: React.FC<Props> = ({ onClick }) => {
	return (
		<Link to="/" className="logo-link" onClick={onClick}>
			<motion.div variants={bloatHoverVariants} whileHover="hover" className="logo-container">
				<div className="logo">
					<VwLogo />
				</div>
				<h1>Village West</h1>
			</motion.div>
		</Link>
	);
};

export default LogoTitle;
