import React from 'react';
import { v4 as uuid } from 'uuid';
import { InvTransactionsList } from '../../../api/types/Response';

import Transaction from './Transaction';

interface Props {
	data: InvTransactionsList;
}

const InvTransactions: React.FC<Props> = ({ data }) => {
	return (
		<section className="overview-section transactions-container">
			<header className="overview-header">Transaction History:</header>
			<div className="trans-table-wrapper">
				<table className="transaction-table">
					<tbody>
						{data.map((transaction) => (
							<Transaction key={uuid()} transaction={transaction} />
						))}
					</tbody>
				</table>
			</div>
		</section>
	);
};

export default InvTransactions;
