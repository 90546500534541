import React from 'react';
import { motion } from 'framer-motion';
import { AppState } from '../utils/CustomHooks';
import Auth from '../components/Auth';

interface Props {
	appState: AppState;
}

const AuthContainter: React.FC<Props> = ({ appState }) => {
	const containerVariants = {
		show: {
			x: [10000, 0, 0, 0],
			scale: [0.75, 0.75, 0.75, 1],
			transition: {
				ease: [0, 1.01, 0.62, 1],
				duration: 2,
				when: 'beforeChildren',
			},
		},
	};

	return (
		<motion.div
			initial="hidden"
			animate="show"
			variants={containerVariants}
			className="login-signup-container"
		>
			<main>
				<Auth appState={appState} />
			</main>
			<div className="circle c-one"></div>
			<div className="circle c-two"></div>
		</motion.div>
	);
};

export default AuthContainter;
