import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

import InvData from './Overview/InvData';
import InvPerf from './Overview/InvPerf';
import InvTransactions from './Overview/InvTransactions';
import { InvDashboardData, InvPerformance, InvTransactionsList } from '../../api/types/Response';

interface Props {
	invId: string | null;
	invData: InvDashboardData | null;
	perfData: InvPerformance | null;
	transactionsList: InvTransactionsList | null;
}

const Overview: React.FC<Props> = ({ invId, invData, perfData, transactionsList }) => {
	const renderInvData = (): JSX.Element => {
		return <>{invData ? <InvData data={invData} /> : <ScaleLoader color={'7a0021'} />}</>;
	};

	const renderInvPerf = (): JSX.Element => {
		return <>{perfData ? <InvPerf data={perfData} /> : <ScaleLoader color={'7a0021'} />}</>;
	};

	const renderInvTransactions = (): JSX.Element => {
		return (
			<>
				{transactionsList ? (
					<InvTransactions data={transactionsList} />
				) : (
					<ScaleLoader color={'7a0021'} />
				)}
			</>
		);
	};

	return (
		<div className="overview-container">
			<main>
				<div className="glass">
					<div className="g-container card left-cont">
						{invId ? renderInvPerf() : 'Not an investor'}
					</div>
					<div className="g-container right-cont">
						<div className="inv-data-cont card">{invId ? renderInvData() : 'Not an investor'}</div>
						<div className="inv-trans-cont card">
							{invId ? renderInvTransactions() : 'Not an investor'}
						</div>
					</div>
				</div>
			</main>
			<div className="circle c-one"></div>
			<div className="circle c-two"></div>
		</div>
	);
};

export default Overview;
