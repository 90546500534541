import React from 'react';

const MoreDetails: React.FC = () => {
	return (
		<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0)">
				<path d="M24.3094 9.01611H6.02344V11.9801H24.3094V9.01611Z" fill="#909090" />
				<path
					d="M33.2657 31.3016C34.6833 28.7976 34.2722 25.607 32.2103 23.5473C30.9905 22.3275 29.3686 21.6543 27.6378 21.6543C25.9069 21.6543 24.2827 22.3275 23.0629 23.5473C21.8431 24.7671 21.1699 26.3891 21.1699 28.1221C21.1699 29.8485 21.8431 31.4727 23.0629 32.6969C24.2827 33.9123 25.9047 34.5855 27.6333 34.5855C28.7598 34.5855 29.8507 34.2989 30.8195 33.7523L38.0672 41L40.5134 38.5515L33.2657 31.3016ZM30.3595 30.8417C28.9109 32.2903 26.3691 32.2903 24.9204 30.8417C23.4207 29.3397 23.4207 26.9023 24.9204 25.4004C25.6447 24.676 26.6135 24.2761 27.6422 24.2761C28.6709 24.2761 29.6352 24.676 30.3618 25.4004C31.0883 26.1247 31.4882 27.0934 31.4882 28.1221C31.4882 29.1509 31.0861 30.1151 30.3595 30.8417Z"
					fill="#909090"
				/>
				<path
					d="M29.6218 36.2163H4.12598C3.7216 36.2163 3.39499 35.8875 3.39499 35.4876V3.63941C3.39499 3.23503 3.7216 2.90842 4.12598 2.90842H29.6218C30.0262 2.90842 30.3506 3.23503 30.3506 3.63941V19.8857C31.406 20.1167 32.3903 20.5678 33.2612 21.2121V3.63941C33.2612 1.63307 31.6304 0 29.6218 0H4.12598C2.11964 0 0.486572 1.63307 0.486572 3.63941V35.4876C0.486572 37.4917 2.11964 39.1248 4.12598 39.1248H29.6218C31.1438 39.1248 31.8637 38.7648 32.4058 37.4361L31.086 36.1164C30.5995 36.3208 29.8307 36.2163 29.6218 36.2163Z"
					fill="#909090"
				/>
				<path d="M24.3094 17.3706H6.02344V20.3346H24.3094V17.3706Z" fill="#909090" />
			</g>
			<defs>
				<clipPath id="clip0">
					<rect width="41" height="41" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default MoreDetails;
