import axios, { AxiosInstance, AxiosRequestConfig, Method } from 'axios';

import { VwApiEndpoint } from './types/Endpoints';

export type HttpMethod = Method;

export class VwApiClient {
	static axiosWrapper: AxiosInstance = axios.create({
		baseURL: false ? 'http://localhost:7071/api/' : 'https://vw-accounting.azurewebsites.net/api/',
	});

	static async makeRequest<T, R>(url: VwApiEndpoint, method: HttpMethod, params: T): Promise<R> {
		const config: AxiosRequestConfig = { ...this.axiosWrapper.defaults, method, params, url };
		return await this.axiosWrapper
			.request<R>(config)
			.then((response) => response.data)
			.catch((err) => err);
	}
}
