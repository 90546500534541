import { VwApiClient } from '../Client';
import { BasicParams, InvestorBasicParams } from '../types/Params';
import {
	AllInvestorInfo,
	InvDashboardData,
	InvPerformance,
	InvTransactionsList,
} from '../types/Response';
import { VwApiEndpoint } from '../types/Endpoints';

export const getInvestorDashboardData = async (
	params: InvestorBasicParams
): Promise<InvDashboardData> => {
	return VwApiClient.makeRequest<InvestorBasicParams, InvDashboardData>(
		VwApiEndpoint.GetInvestorDashboardData,
		'get',
		params
	);
};

export const getInvestorPerformance = async (
	params: InvestorBasicParams
): Promise<InvPerformance> => {
	return VwApiClient.makeRequest<InvestorBasicParams, InvPerformance>(
		VwApiEndpoint.GetInvestorPerformance,
		'get',
		params
	);
};

export const getInvestorTransactions = async (
	params: InvestorBasicParams
): Promise<InvTransactionsList> => {
	return VwApiClient.makeRequest<InvestorBasicParams, InvTransactionsList>(
		VwApiEndpoint.GetInvestorTransactions,
		'get',
		params
	);
};

export const getAllInvestorsInfo = async (params: BasicParams): Promise<AllInvestorInfo> => {
	return VwApiClient.makeRequest<BasicParams, AllInvestorInfo>(
		VwApiEndpoint.GetAllInvestorsInfo,
		'get',
		params
	);
};
