import { stripExtraCharacters } from './Utils';

export const determineEmailError = (inpt: string): string | null => {
	return inpt === '' ? 'Invalid email address.' : null;
};

export const determineFnameError = (inpt: string): string | null => {
	return inpt === '' ? 'First Name cannot be empty.' : null;
};

export const determineLnameError = (inpt: string): string | null => {
	return inpt === '' ? 'Last Name cannot be empty.' : null;
};

export const determinePhoneNumError = (inpt: string): string | null => {
	return stripExtraCharacters(inpt).length < 10 ? 'Phone Number is incomplete.' : null;
};

export const determinePasswordError = (inpt: string): string | null => {
	return inpt.length < 8 ? 'Password must be greater than 8 characters.' : null;
};

export const determineConfPasswordError = (inpt: string, otherPword: string): string | null => {
	return inpt !== otherPword ? 'Passwords must match!' : null;
};
