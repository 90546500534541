import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import { AppState } from '../utils/CustomHooks';
import Typewriter from '../components/Typewriter';
import LearnMoreButton, { ButtonStyles } from '../components/LearnMoreButton';
import SpaceNeedle from '../assets/icons/SpaceNeedle';
import MountainSky from '../assets/icons/MountainSky';

interface Props {
	appState: AppState;
}

enum LeftPaneIcons {
	SpaceNeedle,
	MountainSky,
}

const Home: React.FC<Props> = () => {
	const [animatingIcon, setAnimatingIcon] = useState<LeftPaneIcons>(LeftPaneIcons.SpaceNeedle);
	useEffect(() => {
		const timeout = setTimeout(() => {
			switch (animatingIcon) {
				case LeftPaneIcons.SpaceNeedle:
					setAnimatingIcon(LeftPaneIcons.MountainSky);
					break;
				case LeftPaneIcons.MountainSky:
					setAnimatingIcon(LeftPaneIcons.SpaceNeedle);
					break;
			}
		}, 6000);

		return () => clearTimeout(timeout);
	}, [animatingIcon]);

	const renderTriangles = (): JSX.Element => {
		return (
			<>
				<svg
					preserveAspectRatio="none"
					height="100%"
					width="75%"
					viewBox="0 0 100 75"
					className="triangle y"
					xmlns="http://www.w3.org/2000/svg"
					fill="#FFD633"
				>
					<polygon points="0 0, 100 100, 0 100" />
				</svg>
				<svg
					preserveAspectRatio="none"
					height="100%"
					width="65%"
					viewBox="0 0 100 100"
					className="triangle m"
					xmlns="http://www.w3.org/2000/svg"
					fill="#7A0021"
				>
					<polygon points="0 100, 0 0, 100 0" />
				</svg>
			</>
		);
	};

	const renderAnimatingIcon = (): JSX.Element => {
		const animate = {
			opacity: [0, 1, 0],
			x: [-10, 0, 10],
			y: [0, 0, 0],
			scale: [0.9, 1, 0.9],
			transition: {
				duration: 6,
				ease: 'linear',
			},
		};

		switch (animatingIcon) {
			case LeftPaneIcons.SpaceNeedle:
				return (
					<motion.div animate={animate} className="home-icon">
						<SpaceNeedle />
					</motion.div>
				);
			case LeftPaneIcons.MountainSky:
				return (
					<motion.div animate={animate} className="home-icon">
						<MountainSky />
					</motion.div>
				);
		}
	};

	return (
		<div className="home-container">
			{renderTriangles()}
			<div className="half l">{renderAnimatingIcon()}</div>
			<div className="half r">
				<Typewriter />
				<div className="home-lmb">
					<LearnMoreButton style={ButtonStyles.MaroonBackground} />
				</div>
			</div>
		</div>
	);
};

export default Home;
