import React from 'react';

const Plus: React.FC = () => {
	return (
		<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M19.5 16.5H33V19.5H19.5V33H16.5V19.5H3V16.5H16.5V3H19.5V16.5Z"
				fill="#7A0021"
			/>
		</svg>
	);
};

export default Plus;
