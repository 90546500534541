import React from 'react';
import { motion } from 'framer-motion';
import { leftHoverVariants } from '../../motions/SignUpControlMotions';

interface Props {
	onClick: () => JSX.Element | undefined;
}

const LeftArrow: React.FC<Props> = ({ onClick }) => {
	return (
		<div onClick={onClick} className="left-arrow">
			<motion.svg
				variants={leftHoverVariants}
				whileHover="hover"
				width="48"
				height="36"
				viewBox="0 0 48 36"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M24 6L26.82 8.115L15.66 16.5H40V19.5H15.66L26.84 27.87L24 30L8 18L24 6Z"
					fill="#7A0021"
				/>
			</motion.svg>
		</div>
	);
};

export default LeftArrow;
