import React from 'react';
import { EquityInfoObs } from '../../../api/types/Response';
import Minus from '../../../assets/icons/Minus';
import Plus from '../../../assets/icons/Plus';
import Refresh from '../../../assets/icons/Refresh';

interface Props {
	eqInfo: EquityInfoObs;
}

const formatDate = (d: Date): string => {
	return `${d.getMonth() + 1}/${d.getDate() + 1}/${d.getFullYear()}`;
};

const Equity: React.FC<Props> = ({ eqInfo }) => {
	return (
		<tr className="data-row">
			<td>{eqInfo.display}</td>
			<td>{eqInfo.ticker}</td>
			<td>
				<Plus />
				<Minus />
				<Refresh />
			</td>
			<td>{formatDate(new Date(eqInfo.recentUpdate))}</td>
			<td>{eqInfo.sharesOwned.toFixed(2)}</td>
			<td>${eqInfo.positionSize.toFixed(2)}</td>
		</tr>
	);
};

export default Equity;
