import React from 'react';
import AdminCheck from '../../../assets/icons/AdminCheck';
import BarChart from '../../../assets/icons/BarChart';
import PeopleOutline from '../../../assets/icons/PeopleOutline';
import TripleBar from '../../../assets/icons/TripleBar';
import UpTrendline from '../../../assets/icons/UpTrendline';
import { AppState } from '../../../utils/CustomHooks';
import { AccountingTabs } from '../Accounting';

interface Props {
	appState: AppState;
	activeTab: AccountingTabs;
	setActiveTab: React.Dispatch<React.SetStateAction<AccountingTabs>>;
}

const AccountingLeftPane: React.FC<Props> = ({ appState, activeTab, setActiveTab }) => {
	return (
		<div className="acct-lp">
			<div className="lp-header">
				<AdminCheck />
				<p>Hi, {appState.currUser.userInfo?.fname}</p>
			</div>
			<div className="lp-menu">
				<div
					className={
						activeTab === AccountingTabs.Investors
							? 'lp-menu-option lp-active'
							: 'lp-menu-option lp-inactive'
					}
					onClick={() => setActiveTab(AccountingTabs.Investors)}
				>
					<PeopleOutline isActive={activeTab === AccountingTabs.Investors} />
					<p>Investors</p>
					<TripleBar isActive={activeTab === AccountingTabs.Investors} />
				</div>
				<div
					className={
						activeTab === AccountingTabs.Equities
							? 'lp-menu-option lp-active'
							: 'lp-menu-option lp-inactive'
					}
					onClick={() => setActiveTab(AccountingTabs.Equities)}
				>
					<BarChart isActive={activeTab === AccountingTabs.Equities} />
					<p>Equities</p>
					<TripleBar isActive={activeTab === AccountingTabs.Equities} />
				</div>
				<div
					className={
						activeTab === AccountingTabs.VwAccount
							? 'lp-menu-option lp-active'
							: 'lp-menu-option lp-inactive'
					}
					onClick={() => setActiveTab(AccountingTabs.VwAccount)}
				>
					<UpTrendline isActive={activeTab === AccountingTabs.VwAccount} />
					<p>VW Account</p>
					<TripleBar isActive={activeTab === AccountingTabs.VwAccount} />
				</div>
			</div>
		</div>
	);
};

export default AccountingLeftPane;
