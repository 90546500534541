import React, { useState } from 'react';

import { AuthAction, AuthStatus, SignInInfo, SignUpInfo, VwUser } from './Auth';
import { defaultUser } from './Defaults';
import { Pages } from '../components/NavComponents/Navigation';

export interface CurrUserContext {
	currUser?: VwUser;
	setCurrUser: any;
	isLoggedIn: boolean;
	setIsLoggedIn: any;
}

export const useCollectSignUpInfo = () => {
	const [signUpInfo, setSignUpInfo] = useState<SignUpInfo>({
		email: '',
		password: '',
		confPassword: '',
		fname: '',
		lname: '',
		phoneNumber: '',
	});
	return { signUpInfo, setSignUpInfo };
};

export const useSignInInfo = () => {
	const [signInInfo, setSignInInfo] = useState<SignInInfo>({
		email: '',
		password: '',
	});
	return { signInInfo, setSignInInfo };
};

export const useAuthAction = () => {
	const [authAction, setAuthAction] = useState<AuthAction>({
		status: AuthStatus.Unstarted,
		payload: null,
	});

	return { authAction, setAuthAction };
};

export const useAuthError = () => {
	const [authError, setAuthError] = useState<string | null>(null);
	return { authError, setAuthError };
};

// Overall application state
export interface AppState {
	readonly currUser: VwUser;
	readonly setCurrUser: React.Dispatch<React.SetStateAction<VwUser>>;
	readonly isLoggedIn: boolean;
	readonly setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
	readonly activePage: Pages;
	readonly setActivePage: React.Dispatch<React.SetStateAction<Pages>>;
}

export const useAppState = (): AppState => {
	const [currUser, setCurrUser] = useState<VwUser>(defaultUser());
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const [activePage, setActivePage] = useState<Pages>(Pages.Home);

	return {
		currUser,
		setCurrUser,
		isLoggedIn,
		setIsLoggedIn,
		activePage,
		setActivePage,
	};
};
