import React from 'react';
import { AllEquityInfo, AllInvestorInfo } from '../../../api/types/Response';
import { AccountingTabs } from '../Accounting';
import EqTableContainer from './EqTableContainer';
import InvTableContainer from './InvTableContainer';

interface Props {
	activeTab: AccountingTabs;
	invInfoList: AllInvestorInfo | null;
	eqInfoList: AllEquityInfo | null;
}

const AccountingRightPane: React.FC<Props> = ({ activeTab, invInfoList, eqInfoList }) => {
	const renderRpContent = (): JSX.Element => {
		switch (activeTab) {
			case AccountingTabs.Investors:
				return invInfoList ? <InvTableContainer invInfoList={invInfoList} /> : <></>;
			case AccountingTabs.Equities:
				return eqInfoList ? <EqTableContainer eqInfoList={eqInfoList} /> : <></>;
			case AccountingTabs.VwAccount:
				return <div>Vw account</div>;
		}
	};

	return <div className="acct-rp">{renderRpContent()}</div>;
};

export default AccountingRightPane;
