export const bloatHoverVariants = {
	hover: {
		scale: 1.2,
		transition: {
			duration: 0.3,
		},
	},
};

export const navLinkHoverVariants = {
	hover: {
		scale: 1.2,
		textDecoration: 'underline #7a0021',
		color: '#c87822',
		transition: {
			duration: 0.3,
			ease: 'linear',
		},
	},
};
