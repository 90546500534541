import { VwApiClient } from '../Client';
import { VwApiEndpoint } from '../types/Endpoints';
import { ConfirmOnlineUserParmas, OnlineUserParams } from '../types/Params';
import { AuthenticatedUser, PostResponse } from '../types/Response';

export const getSingleOnlineUserInfo = async (
	params: OnlineUserParams
): Promise<AuthenticatedUser> => {
	return VwApiClient.makeRequest<OnlineUserParams, AuthenticatedUser>(
		VwApiEndpoint.GetOnlineUserInfo,
		'get',
		params
	);
};

export const confirmOnlineUser = async (params: ConfirmOnlineUserParmas): Promise<PostResponse> => {
	return VwApiClient.makeRequest<ConfirmOnlineUserParmas, PostResponse>(
		VwApiEndpoint.ConfirmOnlineUser,
		'post',
		params
	);
};
