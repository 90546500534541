import React from 'react';

import { AppState } from '../../utils/CustomHooks';
import { logOut } from '../../utils/Auth';
import { defaultUser } from '../../utils/Defaults';
import LogoTitle from './LogoTitle';
import Navigation, { Pages } from './Navigation';
import AccountHeader from './AccountHeader';

export interface Props {
	appState: AppState;
}

const DesktopNav: React.FC<Props> = ({ appState }) => {
	const { setCurrUser, setIsLoggedIn, setActivePage } = appState;

	const logoutHandler = async () => {
		await logOut().catch((err) => console.log(err));
		setIsLoggedIn(false);
		setCurrUser(defaultUser());
	};

	return (
		<nav className="desktop-nav-container">
			<LogoTitle onClick={() => setActivePage(Pages.Home)} />
			<Navigation appState={appState} />
			<AccountHeader appState={appState} logoutHandler={logoutHandler} />
		</nav>
	);
};

export default DesktopNav;
