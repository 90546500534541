export enum VwApiEndpoint {
	GetAllEquitiesInfo = 'eq/all',
	AddInvestorTransaction = 'inv/add-transaction',
	GetInvestorDashboardData = 'inv/dashboard-data',
	GetAllInvestorsInfo = 'inv/all',
	GetInvestorPerformance = 'inv/performance',
	GetInvestorTransactions = 'inv/transactions',
	UpdateAllAccounting = 'vw/update-all-accounting',
	ConfirmOnlineUser = 'online-user/confirm',
	GetOnlineUserInfo = 'online-user/info',
}
