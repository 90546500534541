import React from 'react';

const SpaceNeedle: React.FC = () => {
	return (
		<svg
			width="476"
			height="650"
			viewBox="0 0 476 650"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M195.834 566.848C189.393 514.709 165.608 319.42 165.608 267.95C165.608 214.446 184.058 154.171 187.819 140.962H192.424C192.424 140.962 203.704 140.962 208.98 139.178C211.953 138.147 216.384 135.639 219.736 133.605H231.453L237.341 121.929H241.713V115.547H229.005V112.649H229.063C229.063 112.649 217.52 106.742 202.13 103.37C186.711 99.9143 172.661 96.5982 172.661 96.5982C172.661 96.5982 168.231 93.6164 171.379 91.9166C174.527 90.2446 178.462 88.1267 178.462 86.4547C178.462 84.727 178.462 83.9189 178.462 83.9189H173.507C172.545 81.8846 170.505 78.7078 165.928 75.6703C160.973 72.3263 153.686 70.3199 149.343 69.4282V33.5917H142.843V69.4561C138.471 70.3199 131.126 72.3263 126.229 75.6703C121.652 78.7078 119.583 81.8846 118.592 83.891H113.695C113.695 83.891 113.695 84.6991 113.695 86.4269C113.695 88.1267 117.601 90.2446 120.778 91.8887C123.984 93.6164 119.525 96.5982 119.525 96.5982C119.525 96.5982 105.417 99.9143 90.085 103.342C74.6948 106.742 63.123 112.649 63.123 112.649V115.547H50.4727V121.929H57.614L63.3562 133.605H72.4504C75.8024 135.611 80.2912 138.147 83.206 139.151C88.5401 140.99 99.7038 140.99 99.7038 140.99H103.172C106.583 157.821 122.119 239.443 116.96 302.226C114.307 334.551 86.5289 518.945 79.2127 566.848H42.5736V589.197H230.695V566.848H195.834ZM75.715 126.248V119.755H218.89V126.248H75.715ZM105.679 566.848C111.013 521.899 130.222 356.538 132.612 294.563C135.119 231.361 121.507 157.961 118.155 140.99H132.583C133.253 154.338 135.556 194.048 137.859 225.76C140.307 259.813 150.217 507.325 152.666 566.876L105.679 566.848ZM154.502 213.861C147.827 213.861 145.874 156.651 145.495 140.99H171.7C170.679 150.13 163.159 213.945 154.502 213.861Z"
				fill="black"
			/>
		</svg>
	);
};

export default SpaceNeedle;
