import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { AppState } from '../utils/CustomHooks';
import CreateAccount from './CreateAccount';
import Login from './Login';

interface Props {
	appState: AppState;
}

export enum AuthMethod {
	SignUp,
	Login,
}

const Auth: React.FC<Props> = ({ appState }) => {
	const [authMethod, setAuthMethod] = useState<AuthMethod>(AuthMethod.Login);

	const x: number = 500;

	const loginVariants = {
		hidden: {
			opacity: 0,
			x,
		},
		show: {
			opacity: 1,
			x: 0,
			transition: {
				ease: 'easeIn',
				bounce: 0,
			},
		},
		exit: {
			opacity: 0,
			x,
			transition: {
				ease: 'easeOut',
			},
		},
	};

	const signUpVariants = {
		hidden: {
			opacity: 0,
			x: -x,
		},
		show: {
			opacity: 1,
			x: 0,
			transition: {
				ease: 'easeIn',
				bounce: 0,
			},
		},
		exit: {
			opacity: 0,
			x: -x,
			transition: {
				ease: 'easeOut',
			},
		},
	};

	return (
		<AnimatePresence exitBeforeEnter>
			{authMethod === AuthMethod.Login && (
				<motion.section
					key="login"
					initial="hidden"
					animate="show"
					variants={loginVariants}
					className="glass"
					exit="exit"
				>
					<Login appState={appState} authMethod={authMethod} setAuthMethod={setAuthMethod} />
				</motion.section>
			)}
			{authMethod === AuthMethod.SignUp && (
				<motion.section
					key="createaccount"
					initial="hidden"
					animate="show"
					variants={signUpVariants}
					className="glass"
					exit="exit"
				>
					<CreateAccount
						appState={appState}
						authMethod={authMethod}
						setAuthMethod={setAuthMethod}
					/>
				</motion.section>
			)}
		</AnimatePresence>
	);
};

export default Auth;
